import React from "react"

const GridWrapper = ({ children, nav }) => {
  return (
    <div
      className={`grid grid-cols-gridMobile md:grid-cols-gridLaptop xl:grid-cols-gridDesk ${
        nav
          ? "fixed px-0 z-20 w-full bg-gradient-to-b from-baseDark via-baseDark"
          : "px-4 md:px-0 "
      }`}
    >
      {children}
    </div>
  )
}

export default GridWrapper
