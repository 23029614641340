import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Navigation from "./Navigation"
import MobileMenu from "./MobileMenu"
import GridWrapper from "./GridWrapper"

const Layout = ({ children }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [contentHidden, setContentHidden] = useState(true)

  useEffect(() => {
    setContentHidden(false)
  }, [])

  return (
    <div
      className={`bg-baseDark ${
        contentHidden
          ? "hidden"
          : "bg-baseDark z-10 font-bodyText relative overflow-hidden"
      }`}
    >
      <GridWrapper nav>
        <Navigation
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
        <MobileMenu
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
      </GridWrapper>
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
