import React from "react"
import { Link } from "gatsby"

// images
import logo from "../../images/logo.svg"

const Navigation = ({ setMobileNavOpen, mobileNavOpen }) => {
  const handleClick = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  return (
    <>
      <div></div>
      <nav className="flex flex-row justify-between items-center text-offWhite sm:pb-8 md:py-8 z-10">
        <div
          className="m-0 font-headlineBold text-2xl hidden md:block"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <Link to="/#" className="py-6">
            charlie <span className="text-accentBlue">billadeau</span>
          </Link>
        </div>
        <div className="m-0 md:hidden block">
          <Link to="/#" className="">
            <img
              aria-label="return to top"
              className="w-14 absolute top-0 left-0"
              src={logo}
              alt="charlie billadeau logo"
            />
          </Link>
        </div>
        <ul
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
          className="navbar flex-row items-center justify-around m-0 md:flex hidden"
        >
          <li className="m-0 md:px-2 lg:px-6 tracking-widest font-[500] text-[12px] xl:text-sm">
            <Link to="/#portfolio" className="underLine uppercase">
              Portfolio
            </Link>
          </li>
          <li className="m-0 md:px-2 lg:px-6 tracking-widest font-[500] text-[12px] xl:text-sm">
            <Link to="/#experience" className="underLine uppercase">
              Experience
            </Link>
          </li>
          <li className="m-0 md:px-2 lg:px-6 tracking-widest font-[500] text-[12px] xl:text-sm">
            <Link to="/#about" className="underLine uppercase">
              About
            </Link>
          </li>
          <li className="m-0 md:px-2 lg:px-6 tracking-widest font-[500] text-[12px] xl:text-sm">
            <Link to="/#contact" className="underLine uppercase">
              Contact
            </Link>
          </li>
          <li className="pl-4 z-10">
            <a
              href="https://crbilladeau.github.io/resume/"
              className="btn-gradient tracking-widest font-[500] text-[12px] xl:text-sm uppercase md:px-2 lg:px-6 py-1 xl:py-2 text-baseDark text-center hover:text-offWhite md:block"
            >
              Resume
            </a>
          </li>
        </ul>
        {/* MOBILE NAV BUTTON */}
        <button
          aria-label="open navigation menu button"
          className="flex md:hidden px-4 py-4 cursor-pointer"
          onClick={handleClick}
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        >
          <svg viewBox="0 0 100 50" width="25" height="25" fill="#dadada">
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </button>
      </nav>
      <div></div>
    </>
  )
}

export default Navigation
