import React from "react"

// images
// import linesShapes from "../../images/lines-shapes.svg"
import github from "../../images/github.svg"
import linkedin from "../../images/linkedin.svg"
import twitter from "../../images/twitter.svg"

const Menu = ({ mobileNavOpen, setMobileNavOpen }) => {
  const handleClose = () => {
    setMobileNavOpen(false)
  }
  return (
    <nav
      className={`z-20 flex flex-col justify-between bg-baseDark text-left p-8 min-h-screen fixed top-0 left-0 ease-in-out duration-500 w-full overflow-hidden ${
        mobileNavOpen ? "translate-x-0" : "translate-x-full"
      }`}
      mobileNavOpen={mobileNavOpen}
    >
      <div className="mt-[25%]">
        <button
          aria-label="close navigation menu button"
          className="absolute top-0 right-0 py-7 px-4 cursor-pointer"
          onClick={handleClose}
        >
          <svg
            fill="#dadada"
            xmlns="http://www.w3.org/2000/svg"
            height="22"
            width="22"
            viewBox="0 0 329.26933 329"
          >
            <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
          </svg>
        </button>
        <ul>
          <li className="py-6">
            <a
              onClick={handleClose}
              className="underLineMobile text-lg uppercase text-offWhite decoration-none ease-linear duration-300 hover:text-accentYellow"
              href="/#portfolio"
            >
              Portfolio
            </a>
          </li>
          <li className="py-6">
            <a
              onClick={handleClose}
              className="underLineMobile text-lg uppercase text-offWhite decoration-none ease-linear duration-300 hover:text-accentYellow"
              href="/#experience"
            >
              Experience
            </a>
          </li>
          <li className="py-6">
            <a
              onClick={handleClose}
              className="underLineMobile text-lg uppercase text-offWhite decoration-none ease-linear duration-300 hover:text-accentYellow"
              href="/#about"
            >
              About
            </a>
          </li>
          <li className="py-6">
            <a
              onClick={handleClose}
              className="underLineMobile text-lg uppercase text-offWhite decoration-none ease-linear duration-300 hover:text-accentYellow"
              href="/#contact"
            >
              Contact
            </a>
          </li>
        </ul>
        {/* <img
          className="absolute top-20 right-[-6rem] w-full sm:w-2/3 transform z-0 -scale-x-[1] -rotate-[10deg]"
          src={linesShapes}
          alt=""
        /> */}
      </div>
      <div className="flex flex-col">
        <div className="py-6 flex flex-row justify-center">
          <a
            href="https://crbilladeau.github.io/resume/"
            className="btn-gradient tracking-widest font-[500] text-sm uppercase py-2 px-6 text-baseDark text-center hover:text-offWhite md:block"
          >
            Resume
          </a>
        </div>
        <div className="flex flex-row justify-around mt-8">
          <a
            href="https://github.com/crbilladeau"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-8" src={github} alt="github" />
          </a>
          <a
            href="https://www.linkedin.com/in/charlie-billadeau-033/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-8" src={linkedin} alt="linkedin" />
          </a>
          <a
            href="https://twitter.com/charlie_devs"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-8" src={twitter} alt="twitter" />
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Menu
